<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col cols="12">
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn icon :to="`/team/${$route.params.team}`" exact>
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <h2 class="white--text">Mitglied bearbeiten</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center" class="mt-4" v-if="mitglied">
            <v-col cols="10" class="px-2 pb-0">
              <v-select
                filled
                rounded
                v-model="mitglied.rolle"
                :items="rollen"
                label="Rolle"
                v-on:change="update()"
              ></v-select>
            </v-col>
            <v-col cols="10" class="px-2 pb-0">
              <v-text-field
                v-model="mitglied.vorname"
                filled
                rounded
                label="Vorname"
                v-on:change="update()"
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="px-2 py-0">
              <v-text-field
                v-model="mitglied.name"
                filled
                rounded
                label="Nachname"
                v-on:change="update()"
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="px-2 py-0">
              <v-text-field
                v-model="mitglied.adresse"
                filled
                rounded
                label="Straße + Nr."
                v-on:change="update()"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="px-2 py-0">
              <v-text-field
                v-model="mitglied.plz"
                filled
                rounded
                label="Postleitzahl"
                v-on:change="update()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-2 py-0">
              <v-text-field
                v-model="mitglied.ort"
                filled
                rounded
                label="Ort"
                v-on:change="update()"
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="px-2 py-0">
              <v-text-field
                v-model="mitglied.telefon"
                label="Telefon-/Mobilnummer"
                v-on:change="update()"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="px-2 py-0">
              <v-dialog
                ref="geburtsdatumref"
                v-model="geburtsdatumdialog"
                :return-value.sync="geburtsdatum"
                persistent
                width="290px"
                content-class="rounded-xl"
                color="dark"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="parse_datum(geburtsdatum)"
                    label="Geburtsdatum"
                    filled
                    rounded
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-card dark class="rounded-xl">
                  <v-toolbar :color="template.colors.primary">
                    <v-toolbar-title>
                      Geburtsdatum
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-date-picker
                        locale="de"
                        v-model="geburtsdatum"
                        class="rounded-xl"
                        content-class="rounded-xl"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000,
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          rounded
                          :color="template.colors.primary"
                          @click="geburtsdatumdialog = false"
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          text
                          rounded
                          :color="template.colors.primary"
                          :style="`color: ${template.colors.inline_primary_text};`"
                          @click="
                            $refs.geburtsdatumref.save(geburtsdatum)
                            update()
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="10" class="px-2 py-0">
              <v-switch
                rounded
                v-model="geimpft"
                :label="
                  mitglied.geimpft
                    ? 'Person ist geimpft.'
                    : 'Person ist nicht geimpft.'
                "
                v-on:change="geimpft ? (geimpftdialog = true) : update()"
              ></v-switch>
              <v-dialog
                ref="geimpftref"
                v-model="geimpftdialog"
                :return-value.sync="datum"
                persistent
                width="290px"
                content-class="rounded-xl"
                color="dark"
              >
                <template v-slot:activator="{ on, attrs }">
                  <keep-alive>
                    <v-text-field
                      :value="parse_datum(impfdatum)"
                      v-if="geimpft == true"
                      label="Vollständig geimpft seit..."
                      :rules="[rules.required]"
                      filled
                      rounded
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </keep-alive>
                </template>
                <v-card dark class="rounded-xl">
                  <v-toolbar :color="template.colors.primary">
                    <v-toolbar-title>
                      Vollständig geimpft seit...
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-date-picker
                        locale="de"
                        first-day-of-week="1"
                        v-model="impfdatum"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          rounded
                          :color="template.colors.primary"
                          @click="
                            geimpftdialog = false
                            geimpft ? (geimpft = false) : (geimpft = true)
                          "
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          text
                          rounded
                          :color="template.colors.primary"
                          :style="`color: ${template.colors.inline_primary_text};`"
                          @click="
                            $refs.geimpftref.save(impfdatum)
                            update()
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="10" class="px-2 py-0" v-if="!mitglied.geimpft">
              <v-switch
                rounded
                v-model="genesen"
                :label="
                  mitglied.genesen
                    ? 'Person ist genesen.'
                    : 'Person ist nicht genesen.'
                "
                v-on:change="genesen ? (genesendialog = true) : update()"
              ></v-switch>
              <v-dialog
                ref="genesenref"
                v-model="genesendialog"
                :return-value.sync="datum"
                persistent
                width="290px"
                content-class="rounded-xl"
                color="dark"
              >
                <template v-slot:activator="{ on, attrs }">
                  <keep-alive>
                    <v-text-field
                      :value="parse_datum(datum)"
                      v-if="genesen == true"
                      label="Genesen seit..."
                      :rules="[rules.required]"
                      filled
                      rounded
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </keep-alive>
                </template>
                <v-card dark class="rounded-xl">
                  <v-toolbar :color="template.colors.primary">
                    <v-toolbar-title>Genesen seit...</v-toolbar-title>
                  </v-toolbar>
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-date-picker
                        locale="de"
                        first-day-of-week="1"
                        v-model="datum"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          rounded
                          :color="template.colors.primary"
                          @click="
                            genesendialog = false
                            this.mitglied.genesen
                              ? (genesen = false)
                              : (genesen = true)
                          "
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          text
                          rounded
                          :color="template.colors.primary"
                          :style="`color: ${template.colors.inline_primary_text};`"
                          @click="
                            $refs.genesenref.save(datum)
                            update()
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="10" class="px-2 py-0" v-if="mitglied.geimpft">
              <v-switch
                rounded
                v-model="geboostert"
                :label="
                  mitglied.geboostert
                    ? 'Person ist geboostert.'
                    : 'Person ist nicht geboostert.'
                "
                @change="update()"
              ></v-switch>
            </v-col>
            <v-col cols="10" class="px-2 py-0">
              <cardbutton
                :title="
                  start_delete
                    ? 'Entfernen bestätigen'
                    : 'Teammitglied entfernen'
                "
                icon="mdi-account-multiple-remove"
                :textcolor="template.colors.block_text"
                :bgcolor="template.colors.blocks"
                upper
                :loading="deleting"
                @click="start_delete ? deleteMember() : del()"
              />
            </v-col>
            <v-col cols="10" class="px-2 py-0" v-if="start_delete">
              <cardbutton
                title="Entfernen abbrechen"
                :textcolor="template.colors.blocks"
                :bgcolor="template.colors.block_text"
                upper
                :disabled="deleting"
                @click="start_delete = false"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="alert"
      :color="alertcolor"
      top
      centered
      rounded="xl"
      multi-line
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import cardbutton from '../../../elements/cards/card-button'
import { mapGetters } from 'vuex'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'mitglied-bearbeiten',
  data() {
    return {
      mitglied: '',
      start_delete: false,
      deleting: false,
      alert: false,
      alertcolor: '',
      text: '',
      genesen: false,
      geimpft: false,
      geboostert: false,
      geburtsdatum: '',
      impfdatum: '',
      datum: '',
      genesendialog: false,
      geburtsdatumdialog: false,
      geimpftdialog: false,
      activePicker: null,
      rollen: ['Sportler', 'Trainer', 'Betreuer'],
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
      },
    }
  },
  components: {
    cardbutton,
  },
  watch: {
    geburtsdatumdialog(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    if (this.$route.params.team) {
      if (this.team.id != this.$route.params.team) {
        store.dispatch('setTeam', this.$route.params.team)
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.$route.params.team)
        .collection('Mitglieder')
        .doc(this.$route.params.mitglied)
        .onSnapshot((doc) => {
          this.mitglied = doc.data()
          this.mitglied.id = doc.id
          this.mitglied.rolle = this.mitglied.rolle
            ? this.mitglied.rolle
            : 'Sportler'
          this.mitglied.geimpft = this.mitglied.geimpft
            ? this.mitglied.geimpft
            : false

          this.mitglied.genesen = this.mitglied.genesen
            ? this.mitglied.genesen.datum
              ? this.mitglied.genesen
              : {
                  datum: '',
                  timestamp: '',
                }
            : {
                datum: '',
                timestamp: '',
              }
          this.mitglied.impfdatum = this.mitglied.impfdatum || ''
          this.datum = this.mitglied.genesen ? this.mitglied.genesen.datum : ''
          this.geburtsdatum = this.mitglied.geburtsdatum || ''
          this.genesen = this.mitglied.genesen
            ? this.mitglied.genesen.timestamp
              ? true
              : false
            : false
          this.geimpft = this.mitglied.geimpft || false
          this.impfdatum = this.mitglied.impfdatum || ''
          this.geboostert = this.mitglied.geboostert || false
        })
    } else {
      store.dispatch('closeTeam')
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      template: 'template',
    }),
  },
  methods: {
    update() {
      if (this.genesen) {
        if (this.datum) {
          var datum = new Zeit(this.datum, '00:00:00')
          this.mitglied.genesen.datum = datum.getDatum()
          this.mitglied.genesen.timestamp = datum.getTimestamp()
        }
      } else {
        this.mitglied.genesen = false
      }
      if (this.geimpft) {
        this.mitglied.geimpft = true
        this.mitglied.impfdatum = this.impfdatum
        if (this.geboostert) {
          this.mitglied.geboostert = true
        }
      } else {
        this.mitglied.geimpft = false
        this.mitglied.impfdatum = ''
        this.mitglied.geboostert = false
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.$route.params.team)
        .collection('Mitglieder')
        .doc(this.$route.params.mitglied)
        .update({
          vorname: this.mitglied.vorname.trim(),
          name: this.mitglied.name.trim(),
          adresse: this.mitglied.adresse.trim(),
          plz: this.mitglied.plz.trim(),
          ort: this.mitglied.ort.trim(),
          nation: this.mitglied.nation ? this.mitglied.nation.trim() : '',
          telefon: this.mitglied.telefon.trim(),
          rolle: this.mitglied.rolle.trim(),
          geburtsdatum: this.geburtsdatum || '',
          geimpft: this.mitglied.geimpft || false,
          impfdatum: this.mitglied.impfdatum || '',
          genesen: this.mitglied.genesen || false,
          geboostert: this.mitglied.geboostert || false,
        })
        .then(() => {
          this.alert = true
          this.alertcolor = 'success'
          this.text = 'Mitglied wurde erfolgreich gespeichert!'
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
          this.alert = true
          this.alertcolor = 'danger'
          this.text = 'Es ist ein Fehler aufgetreten'
        })
    },
    del() {
      this.start_delete = true
    },
    deleteMember() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.$route.params.team)
        .collection('Mitglieder')
        .doc(this.$route.params.mitglied)
        .delete()
        .then(() => {
          router.push('/team/' + this.team.id)
        })
        .catch((error) => {
          console.log(error)
          this.alert = true
          this.alertcolor = 'danger'
          this.text = 'Es ist ein Fehler aufgetreten'
        })
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
  },
}
</script>
